import { extend } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Table } from 'reactstrap';
import { bindActionCreators } from 'redux';
import GenericActions from '../../actions/genericActions';
import Header from '../Header';
import actions from './actions';

const startDate = moment().subtract(1, 'year').format('YYYY-MM-DD');
const endDate = moment().format('YYYY-MM-DD');
class LocationsDetailPage extends Component {
  componentDidMount() {
    this.updateList();
  }

  updateList() {
    const { routeParams, getEventStats, getLocationDetails } = this.props;
    getLocationDetails(routeParams.id);
    getEventStats({ locationId: routeParams.id, startDate, endDate });
  }

  render() {
    const { locationSummary, locationDetails } = this.props;
    return (
      <div className="page-container">
        <Header currentPage="/locations" />
        <div className="container-fluid">
          <div className="content-box">
            <h3>Location Details</h3>
            <div>Name: {locationDetails.name}</div>
            <div>
              Account: <Link to={`/accounts/${locationDetails.account?.id}`}>{locationDetails.account?.name}</Link>
            </div>
            <Link to={`/locations/${locationDetails.id}/edit`} className="btn btn-success">
              Edit
            </Link>
            <div className="row">
              <div className="col-m">
                <h2>Last 1 Year</h2>
                <Table striped hover responsive size="sm" className="element-list">
                  <tr>
                    <td>Events Completed</td>
                    <td style={{ textAlign: 'right' }}>{locationSummary.eventCount?.toLocaleString()}</td>
                  </tr>
                  <tr>
                    <td>Total Cost to Customer</td>
                    <td style={{ textAlign: 'right' }}>
                      {locationSummary.grossSum?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                    </td>
                  </tr>

                  <tr>
                    <td>Total Count of Players</td>
                    <td style={{ textAlign: 'right' }}>{locationSummary.totalPlayerCount?.toLocaleString()}</td>
                  </tr>

                  <tr>
                    <td>Average Cost per Player</td>
                    <td style={{ textAlign: 'right' }}>
                      {locationSummary.dollarsPerPlayer?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                    </td>
                  </tr>

                  <tr>
                    <td>Total Count of Teams</td>
                    <td style={{ textAlign: 'right' }}>{locationSummary.totalTeamCount?.toLocaleString()}</td>
                  </tr>

                  <tr>
                    <td>Average Cost per Team</td>
                    <td style={{ textAlign: 'right' }}>
                      {locationSummary.dollarsPerTeam?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                    </td>
                  </tr>

                  <tr>
                    <td>Average # Registered Teams</td>
                    <td style={{ textAlign: 'right' }}>{Math.ceil(locationSummary.averageRegisteredTeams)}</td>
                  </tr>
                  <tr>
                    <td>Average Players per Team</td>
                    <td style={{ textAlign: 'right' }}>{Math.ceil(locationSummary.averagePlayers / locationSummary.averageTeams)}</td>
                  </tr>
                </Table>
                <br />
                <Table striped hover responsive size="sm" className="element-list">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Average</th>
                      <th>Max</th>
                    </tr>
                  </thead>

                  <tr>
                    <td>Players per Event</td>
                    <td style={{ textAlign: 'right' }}>{Math.ceil(locationSummary.averagePlayers)}</td>
                    <td style={{ textAlign: 'right' }}>{locationSummary.maxPlayerCount}</td>
                  </tr>

                  <tr>
                    <td>Teams per Event</td>
                    <td style={{ textAlign: 'right' }}>{Math.ceil(locationSummary.averageTeams)}</td>
                    <td style={{ textAlign: 'right' }}>{locationSummary.maxTeamCount}</td>
                  </tr>

                  <tr></tr>
                </Table>
              </div>
            </div>
            {/* <pre>Teams: {JSON.stringify(locationSummary, null, 2)}</pre> */}
          </div>
        </div>
      </div>
    );
  }
}

LocationsDetailPage.propTypes = {
  getLocationDetails: PropTypes.func.isRequired,
  getEventStats: PropTypes.func.isRequired,
  // locationDetailRequest: PropTypes.objectOf(PropTypes.shape),
  locationDetails: PropTypes.objectOf(PropTypes.shape),
  // locationReport: PropTypes.objectOf(PropTypes.shape),
  locationSummary: PropTypes.objectOf(PropTypes.shape),
  // locationReportRequest: PropTypes.objectOf(PropTypes.shape),
  routeParams: PropTypes.objectOf(PropTypes.shape).isRequired,
};
LocationsDetailPage.defaultProps = {
  // locationDetailRequest: {},
  // locationReport: { data: [], metadata: {} },
  locationSummary: {},
  locationDetails: {},
  //   account: {
  //     name: '',
  //   },
  // },
};

const mapStateToProps = (state) => extend({}, state.locations, state.global);
const matchDispatchToProps = (dispatch) => bindActionCreators(extend({}, actions, GenericActions), dispatch);
export default connect(mapStateToProps, matchDispatchToProps)(LocationsDetailPage);
