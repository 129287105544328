const defaultState = {
  // Form Request Status
  locations: { data: [], metadata: {} },
  locationDetails: {},
  locationListRequest: {},
  locationDetailRequest: {},
  locationCreateRequest: {},
  locationEditRequest: {},
  locationDeleteRequest: {},
  locationsReport: { data: [], metadata: {} },
  locationSummary: {
    averageTeams: 0,
    averagePlayers: 0,
    averageRegisteredTeams: 0,
  },
  locationReportRequest: {},
};

/*
 {
      "weeknum": "202208",
      "year": "2022",
      "week": "08",
      "numberOfEvents": 2,
      "players": "83",
      "teams": "17",
      "registeredTeams": "12",
      "grossIncome": "200",
      "account": {
        "id": 14,
        "name": "WhatTrivia",
        "stripeCustomerId": "cus_LFQvlkZuuwNRmO",
        "invoiceToEmail": "james@whattrivia.com",
        "invoiceCCEmail": null,
        "stripeInvoiceFrequency": 1,
        "paymentMethod": "CASH",
        "type": 1,
        "created": "2017-01-09T04:17:12.000Z",
        "modified": "2022-03-08T15:39:24.000Z",
        "regionId": 1
      },
      */

function buildSummary(arr) {
  // const obj = {};
  let teams = 0;
  let players = 0;
  let rt = 0;
  let eventCount = 0;
  let firstEvent = '';
  let lastEvent = '';

  let maxTeamCount = 0;
  let maxPlayerCount = 0;
  let grossSum = 0;

  for (let i = 0; i < arr.length; i += 1) {
    const e = arr[i];
    if (i === 0) {
      firstEvent = e.weeknum;
    }
    if (i === arr.length - 1) {
      lastEvent = e.weeknum;
    }
    teams += parseInt(e.teams, 10);
    players += parseInt(e.players, 10);
    rt += parseInt(e.registeredTeams, 10);
    maxTeamCount = Math.max(maxTeamCount, e.teams);
    maxPlayerCount = Math.max(maxPlayerCount, e.players);
    grossSum += parseInt(e.grossIncome, 10);

    eventCount += 1;
    // console.log('c', eventCount);
  }

  return {
    eventCount,
    firstEvent,
    lastEvent,
    averageTeams: teams / eventCount,
    averagePlayers: players / eventCount,
    averageRegisteredTeams: rt / eventCount,
    totalPlayerCount: players,
    totalTeamCount: teams,
    dollarsPerPlayer: grossSum / players,
    dollarsPerTeam: grossSum / teams,
    maxTeamCount,
    maxPlayerCount,
    grossSum,
  };
}

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case 'LOCATIONS_LIST_STARTED':
      return { ...state, locationListRequest: { working: true } };
    case 'LOCATIONS_LIST_FAILED':
      return { ...state, locationListRequest: { failed: true } };
    case 'LOCATIONS_LIST_SUCCESS':
      return {
        ...state,
        locations: action.payload,
        locationListRequest: { success: true },
      };

    case 'LOCATIONS_DETAIL_STARTED':
      return { ...state, locationDetailRequest: { working: true } };
    case 'LOCATIONS_DETAIL_FAILED':
      return { ...state, locationDetailRequest: { failed: true } };
    case 'LOCATIONS_DETAIL_SUCCESS':
      return {
        ...state,
        locationDetails: action.payload,
        locationDetailRequest: { success: true },
      };

    case 'LOCATIONS_CREATE_STARTED':
      return { ...state, locationCreateRequest: { working: true } };
    case 'LOCATIONS_CREATE_FAILED':
      return { ...state, locationCreateRequest: { failed: true } };
    case 'LOCATIONS_CREATE_SUCCESS':
      return {
        ...state,
        locationDetails: action.payload,
        locationCreateRequest: { success: true },
      };

    case 'LOCATIONS_EDIT_STARTED':
      return { ...state, locationEditRequest: { working: true } };
    case 'LOCATIONS_EDIT_FAILED':
      return { ...state, locationEditRequest: { failed: true } };
    case 'LOCATIONS_EDIT_SUCCESS':
      return {
        ...state,
        locationDetails: action.payload,
        locationEditRequest: { success: true },
      };
    case 'LOCATIONS_REPORT_STARTED':
      return { ...state, locationReportRequest: { working: true } };
    case 'LOCATIONS_REPORT_FAILED':
      return { ...state, locationReportRequest: { failed: true } };
    case 'LOCATIONS_REPORT_SUCCESS':
      return {
        ...state,
        locationReport: action.payload,
        locationSummary: buildSummary(action.payload.data),
        locationReportRequest: { success: true },
      };

    default:
      return state;
  }
};
