// API base URL - To override this with an environment variable set it as `REACT_APP_API_URL`
// export const API_URL = 'https://whattrivia-api-dev.herokuapp.com/api/v1';

export { API_URL, ENDPOINTS, ERRORS, DEFAULT_ERROR_MSG, DEFAULT_SUCCESS_MSG } from './api';

export const EVENT_STATUS = {
  DRAFT: 'DRAFT',
  READY: 'READY',
  PENDING: 'PENDING_APPROVAL',
  COMPLETE: 'COMPLETE',
  // INVOICED: 'INVOICED',
  // PAID: 'PAID',
  // IMPORTED: "IMPORTED",
  CANCELED: 'CANCELED',
};

export const META = {
  PAGE_TITLE_SUFFIX: 'WhatTrivia',
  PAGE_DESCRIPTION: 'Live, Hosted Trivia in Hartford &amp; New Haven Area for your Bar, Fundraiser, Project Graduation or Team Building Event',
  PAGE_KEYWORDS: 'Trivia, Bar Trivia',
};

export const SOCIAL_LINKS = {
  FACEBOOK: 'https://www.facebook.com/WhatTrivia',
  TWITTER: 'https://twitter.com/WhatTrivia',
  GOOGLE_PLUS: 'https://www.google.com',
  GIG_SALAD: 'https://www.gigsalad.com/whattrivia_portland',
};

export const GA_TRACK_ID = 'UA-1729118-16';

export const AWS_BUCKET = 'https://s3.amazonaws.com/whattrivia/img';
export const CMS_URL = 'https://cms.whattrivia.com/studio';
export const HUBSPOT_URL = 'https://app.hubspot.com/';

export const RECAPTCHA_SECRET = '6Leu7GkUAAAAALhbYqzWFKX38bmI-VrUMtXvZ77R';
export const RECAPTCHA_KEY = '6Leu7GkUAAAAAKfeoT8VTRHV_TVt3lKAAPTxAmLa';

export const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL || 'https://www.whattrivia.com/';
// export const SUPABASE_URL = process.env.REACT_APP_SUPABASE_URL || '';
// export const SUPABASE_ANON_KEY = process.env.REACT_APP_SUPABASE_ANON_KEY || '';

export const STRIPE_DASHBOARD_URL = process.env.REACT_APP_STRIPE_SITE_URL || 'https://dashboard.stripe.com/test';
