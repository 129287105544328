import classnames from 'classnames';
import { ErrorMessage, Field, withFormik } from 'formik';
import { map } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import * as Yup from 'yup';

import moment from 'moment/moment';
import { ExternalLink, RefreshCw } from 'react-feather';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Button, FormGroup, InputGroup, InputGroupAddon, Label } from 'reactstrap';
import { FRONTEND_URL } from '../../config';
import AccountSelector from '../../containers/Accounts/AccountSelector';
import HostSelector from '../../containers/Hosts/HostSelector';
import LocationSelector from '../../containers/Locations/LocationSelector';
import Spinner from '../Spinner';
// import Checkbox from '../Forms/formik-checkbox';
import RegionsSelector from '../../containers/Accounts/RegionsSelector';
import TournamentSelector from '../../containers/Tournaments/TournamentSelector';
import { EVENT_STATUSES, EVENT_TYPE, EVENT_TYPES, PAYMENT_METHODS, PAYMENT_TYPE, TOURNAMENT_EVENT_TYPES, generateLiveStreamCode } from '../../utils';
import API from '../../utils/api/accounts';
import Checkbox from '../Forms/formik-checkbox';
// import GoogleDocFileLink from './Widgets/GoogleDocFileLink';

const EventEditForm = ({
  entity,
  values,
  touched,
  errors,
  // dirty,
  isSubmitting,
  // handleChange,
  setFieldValue,
  // handleBlur,
  handleSubmit,

  // entity,
  loading,
  success,
  failed,
  errorMessage,
}) => {
  const [accountDeets, setAccountDeets] = useState({});
  // componentDidUpdate(prevProps) {
  //   const { resetForm, success } = props;
  //   if (prevProps.success === false && success === true) resetForm();
  // }

  const getAccountDetailsLocal = async (id) => {
    const acct = await API.getAccount(id);
    // console.log('account deets', acct);
    setAccountDeets(acct);
  };
  useEffect(() => {
    if (entity.account?.id) {
      getAccountDetailsLocal(entity.account.id);
      setFieldValue('billingAccountId', entity.billingAccountId);
      // values.billingAccountId = entity.billingAccountId;
    }
  }, [entity]);

  const createLiveStreamCode = () => {
    setFieldValue('liveStreamEventCode', generateLiveStreamCode());
  };

  const toggleCheckbox = (fieldName, v, id) => {
    // console.log('toggle checkbox', fieldName, v, id);
    if (v) {
      setFieldValue(fieldName, id);
    } else {
      setFieldValue(fieldName, null);
    }
  };

  // createGoogleSheet = (event) => {
  //   const { onSubmit } = props;
  //   const e = event;
  //   e.pendingAction = 'create-sheet';
  //   // console.log("Create Sheet", event);f
  //   onSubmit(event.id, e, true);
  // };

  const selectAccount = (account) => {
    setFieldValue('account', account);
  };

  const selectLocation = (location) => {
    setFieldValue('location', location);
  };

  const selectTournament = (tournament) => {
    setFieldValue('tournament', tournament);
  };

  return (
    <form onSubmit={handleSubmit}>
      {/* <Prompt when={dirty} message="Navigate away? You have unsaved edits" /> */}
      <div>
        {success && <div className="alert alert-success">Event Updated!</div>}
        {failed && <div className="alert alert-danger">{`Error: ${errorMessage}`}</div>}
      </div>

      {/* <div>{JSON.stringify(dirty)}</div> */}

      <div className="row">
        <div className="col-lg-8">
          <div className="form-row">
            <div className="form-group col-md-4">
              <label htmlFor="accountId">Account</label>
              <AccountSelector selectedAccount={values.account} onUpdate={selectAccount} />
              {errors.account && touched.account && <div className="alert alert-danger">{errors.account}</div>}
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="locationId">Location</label>
              <LocationSelector selectedLocation={values.location} onUpdate={selectLocation} />
              {errors.location && touched.location && <div className="alert alert-danger">{errors.location}</div>}
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="name" className="required">
                Region
              </label>
              <RegionsSelector selectedRegion={values.region} onUpdate={(v) => setFieldValue('region', v)} />
              {errors.region && touched.region && <div className="alert alert-danger">{errors.region}</div>}
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-sm-4">
              <label htmlFor="dateTimeStart">Date</label>
              <Field className="form-control" type="datetime-local" name="dateTimeStart" />
              {errors.dateTimeStart && touched.dateTimeStart && <div className="alert alert-danger">{errors.dateTimeStart}</div>}
            </div>
            <div className="form-group col-sm-2">
              <label htmlFor="eventGross">Cost</label>
              <InputGroup>
                <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                <Field className="form-control" type="number" name="eventGross" />
              </InputGroup>
              {errors.eventGross && touched.eventGross && <div className="alert alert-danger">{errors.eventGross}</div>}
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="hosts">Hosts</label>
              <HostSelector
                selectedHosts={values.hosts}
                onUpdate={(v) => {
                  setFieldValue('hosts', v);
                }}
              />
              {errors.hosts && touched.hosts && <div className="alert alert-danger">{errors.hosts}</div>}
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="hostDetails">Host Notes</label>
              <ReactQuill value={values.hostDetails} onChange={(v) => setFieldValue('hostDetails', v)} rows="3" />
              <small className="form-text text-muted">
                Shows in the{' '}
                <a href={`${FRONTEND_URL}hosts`} target="noreferrer noopener">
                  Host Panel
                </a>
              </small>
              {errors.hostDetails && touched.hostDetails && <div className="alert alert-danger">{errors.hostDetails}</div>}
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="adminDetails">Admin Notes</label>
              <ReactQuill value={values.adminDetails} onChange={(v) => setFieldValue('adminDetails', v)} rows="3" />
              <small className="form-text text-muted">
                Internal only. Shows here and the <Link to={`/events/${entity.id}`}>event view</Link> page.
              </small>
              {errors.adminDetails && touched.adminDetails && <div className="alert alert-danger">{errors.adminDetails}</div>}
            </div>
          </div>
          <h3>Bonus</h3>
          <div className="form-row">
            <div className="form-group col-md-2">
              <FormGroup check inline>
                <Label check>
                  Add{' '}
                  <Field
                    className="form-control"
                    type="radio"
                    name="bonusPointsIsMultiplier"
                    value="0"
                    checked={values.bonusPointsIsMultiplier === false}
                    onChange={() => {
                      setFieldValue('bonusPointsIsMultiplier', false);
                    }}
                  />
                </Label>
              </FormGroup>
              <FormGroup check inline>
                <Label check>
                  Multiply{' '}
                  <Field
                    className="form-control"
                    type="radio"
                    name="bonusPointsIsMultiplier"
                    value="1"
                    checked={values.bonusPointsIsMultiplier === true}
                    onChange={() => {
                      setFieldValue('bonusPointsIsMultiplier', true);
                    }}
                  />
                </Label>
              </FormGroup>
            </div>
            <div className="form-group col-md-2">
              <label htmlFor="bonusPoints">
                {values.bonusPointsIsMultiplier === false && 'Add Points'}
                {values.bonusPointsIsMultiplier === true && 'Multiply By'}
              </label>
              <Field className="form-control" type="number" name="bonusPoints" />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="bonusPointsReason">Special Bonus Reason</label>
              <Field className="form-control" name="bonusPointsReason" />
            </div>
          </div>
          <div className={classnames(values.eventType === EVENT_TYPE.LIVE ? '' : 'd-none')}>
            <h3>Live Stream Properties</h3>
            <div className="form-row">
              <div className="form-group col-sm-4">
                <label htmlFor="liveStreamEventCode">
                  Event Code <RefreshCw onClick={() => createLiveStreamCode()} size={10} />
                </label>
                <Field className="form-control" name="liveStreamEventCode" />
                <ErrorMessage name="liveStreamEventCode" component="div" className="alert alert-danger" />
              </div>
              <div className="form-group col-sm-4">
                <label htmlFor="liveStreamNumberOfQuestions"># of Questions</label>
                <Field className="form-control" name="liveStreamNumberOfQuestions" />
                <ErrorMessage name="liveStreamNumberOfQuestions" component="div" className="alert alert-danger" />
              </div>
              <div className="form-group col-sm-4">
                <label htmlFor="liveStreamPointsMultiplier">Points Multiplier</label>
                <Field className="form-control" name="liveStreamPointsMultiplier" />
                <ErrorMessage name="liveStreamPointsMultiplier" component="div" className="alert alert-danger" />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-sm-6">
                <label htmlFor="liveStreamAnswers">Answers</label>
                <Field className="form-control" name="answerText" component="textarea" rows="8" />
                <ErrorMessage name="liveStreamAnswers" component="div" className="alert alert-danger" />
                <div className="sidenote">One Answer Group per Line, Multiple Answers Separated by ;</div>
              </div>
              <div className="form-group col-sm-6">
                <label htmlFor="liveStreamMetadata">Metadata</label>
                <Field className="form-control" name="liveStreamMetadata" component="textarea" rows="8" />
                <ErrorMessage name="liveStreamMetadata" component="div" className="alert alert-danger" />
              </div>
            </div>
          </div>
          <div className={classnames(values.eventType === EVENT_TYPE.TOURNAMENT ? '' : 'd-none')}>
            <h3>Tournament Properties</h3>
            <div className="form-row">
              <div className="form-group col-sm-8">
                <label htmlFor="tournamentId">Part of Tournament</label>
                <TournamentSelector selectedTournament={values.tournament} onUpdate={selectTournament} />
                <ErrorMessage name="tournamentId" component="div" className="alert alert-danger" />
              </div>
            </div>
            {values.tournament && values.tournament.name && (
              <div>
                <Link to={`/tournaments/${values.tournament.key}`}>View Tournament</Link>
              </div>
            )}
            <div className="form-row">
              <div className="form-group col-sm-4">
                <label htmlFor="tournamentId">Event Type</label>
                <br />
                <Select
                  options={TOURNAMENT_EVENT_TYPES}
                  name="tournamentEventType"
                  value={TOURNAMENT_EVENT_TYPES.filter(({ value }) => value === values.tournamentEventType)}
                  getOptionLabel={({ label }) => label}
                  getOptionValue={({ value }) => value}
                  onChange={({ value }) => setFieldValue('tournamentEventType', value)}
                  classNamePrefix="select"
                />
              </div>
              <div className="form-group col-sm-4">
                <label htmlFor="tournamentMaxTeams">Max Teams at this Event</label>
                <Field className="form-control" name="tournamentMaxTeams" />
                <ErrorMessage name="tournamentMaxTeams" component="div" className="alert alert-danger" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="row">
            <div className="form-group col-sm-6">
              Event Type:
              <br />
              <Select
                options={EVENT_TYPES}
                name="eventType"
                value={EVENT_TYPES.filter(({ value }) => value === values.eventType)}
                getOptionLabel={({ label }) => label}
                getOptionValue={({ value }) => value}
                onChange={({ value }) => setFieldValue('eventType', value)}
                classNamePrefix="select"
              />
            </div>
            <div className="form-group col-sm-6">
              Status:
              <br />
              <Select
                options={EVENT_STATUSES}
                name="status"
                value={EVENT_STATUSES.filter(({ value }) => value === values.status)}
                getOptionLabel={({ label }) => label}
                getOptionValue={({ value }) => value}
                onChange={({ value }) => setFieldValue('status', value)}
                classNamePrefix="select"
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="googleDocURL">Google Sheet URL</label>
            <InputGroup>
              {/* <InputGroupAddon addonType="append"> */}
              {/* <GoogleDocFileLink */}
              {/* event={entity} */}
              {/* onCreateGoogleDoc={() => createGoogleSheet(entity)} */}
              {/* /> */}
              {/* </InputGroupAddon> */}
              <Field className="form-control" type="url" name="googleDocURL" />
            </InputGroup>
            {errors.googleDocURL && touched.googleDocURL && <div className="alert alert-danger">{errors.googleDocURL}</div>}
          </div>
          <div className="form-group">
            <label htmlFor="name">Event Name</label>
            <Field className="form-control" name="name" />
            {errors.name && touched.name && <div className="alert alert-danger">{errors.name}</div>}
          </div>
          <div className="form-group">
            <label htmlFor="googleSheetPrefix">Google Sheet Prefix</label>
            <Field className="form-control" name="googleSheetPrefix" />
            {errors.googleSheetPrefix && touched.googleSheetPrefix && <div className="alert alert-danger">{errors.googleSheetPrefix}</div>}
          </div>
          <h3>Payment Information</h3>
          <div className="form-row">
            <div className="form-group col-md-12">
              <label htmlFor="paymentMethod">Payment Method</label>
              <Field className="form-control" component="select" name="paymentMethod">
                {PAYMENT_METHODS.map((m) => (
                  <option key={m.value} value={m.value}>
                    {m.label}
                  </option>
                ))}
              </Field>
              <ErrorMessage name="paymentMethod" component="div" className="alert alert-danger" />
            </div>
            <div className="form-row">
              <div className="form-group col-md-12">
                {values.paymentMethod === PAYMENT_TYPE.ACCOUNT && (
                  <div>
                    {accountDeets?.billingAccounts &&
                      accountDeets.billingAccounts.map((b) => (
                        <div key={b.id}>
                          <Checkbox
                            name={`${b.name}`}
                            // onChange={() => setFieldValue('billingAccountId', b.id)}
                            onChange={(v) => toggleCheckbox('billingAccountId', v, b.id)}
                            isChecked={values.billingAccountId === b.id}
                          />{' '}
                          <Link to={`/billing-accounts/${b.id}`} title="View Billing Account">
                            <ExternalLink size={12} />
                          </Link>
                        </div>
                      ))}
                    {accountDeets?.billingAccounts && accountDeets?.billingAccounts.length < 1 && (
                      <div>
                        No Billing Accounts on Account. Create one <Link to="/billing-accounts/create">here</Link>.
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="form-group col-md-12">
              <Checkbox
                name="Create Invoice Item on Event Complete"
                onChange={(e) => setFieldValue('isCreateInvoice', e)}
                isChecked={values.isCreateInvoice}
              />
              <ErrorMessage name="isCreateInvoice" component="div" className="alert alert-danger" />
              <br />
              <Checkbox
                name="Automatically Send Invoice"
                onChange={(e) => setFieldValue('isAutoSendInvoice', e)}
                isChecked={values.isAutoSendInvoice}
              />
              <ErrorMessage name="isAutoSendInvoice" component="div" className="alert alert-danger" />
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-8">
          <Link className="btn btn-default" to="/events">
            &laquo; Back to List
          </Link>{' '}
          {isSubmitting || loading ? (
            <Spinner />
          ) : (
            <Button type="submit" color="primary">
              Submit
            </Button>
          )}
        </div>
        <div className="col-md-4" style={{ textAlign: 'right' }}>
          <Link className="btn btn-info" to={`/events/${entity.id}`}>
            View Event
          </Link>{' '}
        </div>
      </div>
    </form>
  );
};

EventEditForm.propTypes = {
  // onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  success: PropTypes.bool,
  failed: PropTypes.bool,
  errorMessage: PropTypes.string,

  entity: PropTypes.objectOf(PropTypes.shape),

  // values added by Formik
  values: PropTypes.objectOf(PropTypes.shape),
  touched: PropTypes.objectOf(PropTypes.shape),
  errors: PropTypes.objectOf(PropTypes.shape),
  // dirty: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  // handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  // handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,

  // resetForm: PropTypes.func.isRequired,
};
EventEditForm.defaultProps = {
  loading: false,
  success: false,
  failed: false,
  errorMessage: '',
  // routeParams: {},
  entity: {},

  values: {},
  touched: {},
  // dirty: false,
  errors: {},
  isSubmitting: false,
};
export default withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ entity }) => ({
    name: entity.name || '',
    account: entity.account || {},
    region: entity.region || {},
    tournament: entity.tournament || {},
    tournamentEventType: entity.tournamentEventType || '',
    tournamentMaxTeams: entity.tournamentMaxTeams || 10,
    location: entity.location || {},
    // isActive: entity.isActive || false,
    // isEventPublic: entity.isEventPublic,
    hosts: entity.hosts || [],
    eventGross: entity.eventGross || 0,
    googleDocURL: entity.googleDocURL || '',
    url: entity.url || '',
    notes: entity.notes || '',
    googleSheetPrefix: entity.googleSheetPrefix || '',
    hostDetails: entity.hostDetails || '',
    adminDetails: entity.adminDetails || '',

    liveStreamEventCode: entity.liveStreamEventCode || '',
    liveStreamAnswers: entity.liveStreamAnswers || '',
    liveStreamMetadata: JSON.stringify(entity.liveStreamMetadata) || '{}',
    answerText: entity.answerText || '',
    liveStreamPointsMultiplier: entity.liveStreamPointsMultiplier || 1,
    liveStreamNumberOfQuestions: entity.liveStreamNumberOfQuestions || 20,

    bonusPoints: entity.bonusPoints || 0,
    bonusPointsReason: entity.bonusPointsReason || '',
    bonusPointsIsMultiplier: entity.bonusPointsIsMultiplier || false,
    isCreateInvoice: entity.isCreateInvoice || false,
    isAutoSendInvoice: entity.isAutoSendInvoice || false,
    invoiceToEmail: entity.invoiceToEmail || '',
    paymentMethod: entity.paymentMethod || '',
    paymentInfo: entity.paymentInfo || '',
    // quickbooksInvoiceId: entity.quickbooksInvoiceId || '',
    // quickbooksInvoiceNum: entity.quickbooksInvoiceNum || '',

    // isInvoiceSent: entity.isInvoiceSent || false,
    // isInvoicePaid: entity.isInvoicePaid || false,

    status: entity.status || 1,
    eventType: entity.eventType || EVENT_TYPE.TOURNAMENT,

    dateTimeStart:
      moment(entity.dateTimeStart).format('YYYY-MM-DDTHH:mm') || `${moment(new Date()).add(1, 'day').format('YYYY-MM-DD')}T18:00:00.000` || '',
  }),

  validationSchema: Yup.object().shape({
    account: Yup.object().required('Please Select an Account'),
    location: Yup.object().required('Please Select a Location'),
    region: Yup.object().required('Please Select a Region'),
    dateTimeStart: Yup.date().required('Please Select a Date'),
  }),
  handleSubmit: (values, { props, setSubmitting }) => {
    const answerRows = values.answerText.split('\n');
    const liveStreamAnswers = [];
    map(answerRows, (answerText, i) => {
      liveStreamAnswers.push({ question: i + 1, answers: answerText });
    });

    // console.log('submit', values.liveStreamMetadata);
    // TODO - if paymentMethod != account, wipe the billingAccountId
    const billingAccountId = values.paymentMethod === PAYMENT_TYPE.ACCOUNT ? values.billingAccountId : null;
    props.onSubmit(props.entity.id, {
      ...values,
      accountId: values.account.id,
      locationId: values.location.id,
      regionId: values.region.id,
      tournamentId: values.tournament?.id || null,
      liveStreamAnswers,
      liveStreamMetadata: JSON.parse(values.liveStreamMetadata),
      billingAccountId,
    });
    setSubmitting(false);
  },
  displayName: 'Event Creation Form',
})(EventEditForm);
