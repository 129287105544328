import React from 'react';
import PropTypes from 'prop-types';
import { map, sortBy } from 'lodash';
import Pagination from 'rc-pagination';
import { Link } from 'react-router-dom';
import { Table } from 'reactstrap';
import { Edit, Mic, User, Home, Users, AlertCircle, CheckCircle } from 'react-feather';
import Spinner from '../Spinner';
import EventHostAvatar from '../Events/Widgets/EventHostAvatar';

const LIMITS = [20, 50, 100];
const UsersTable = ({
  list,
  pagination,
  isLoading,
  isSuccess,
  isFailed,
  errorMessage,
  onPageSizeChange,
  onPageNumberChange,
  onSearchUsers,
  onToggleActive,
}) => (
  <div>
    <input type="text" className="form-control" placeholder="Search..." onChange={(e) => onSearchUsers(e.target.value)} />
    <Table striped hover responsive size="sm" className="element-list">
      <thead>
        <tr>
          <th>Email</th>
          <th>Name</th>
          <th>Nickname</th>
          <th>Google ID</th>
          <th>Groups</th>
          <th>Active</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {isFailed && (
          <tr>
            <td colSpan="6" className="danger">
              {errorMessage}
            </td>
          </tr>
        )}
        {isLoading && (
          <tr>
            <td colSpan="6">
              <Spinner />
            </td>
          </tr>
        )}
        {isSuccess &&
          map(list, (item) => (
            <tr key={item.id}>
              <td>
                <Link to={`/users/${item.id}`} className="action">
                  {item.username}
                </Link>
              </td>
              <td>
                {item.firstName} {item.lastName}
              </td>
              <td>
                <EventHostAvatar host={{ nickname: item.nickname, color: item.color }} />
              </td>
              <td>{item.googleFolderId}</td>
              <td>
                {sortBy(item.groups, 'id').map((group) => {
                  switch (group.name) {
                    case 'Hosts':
                      return (
                        <span key={group.id} title="Host">
                          <Mic color="#009900" />
                        </span>
                      );
                    case 'Bar Owners':
                      return (
                        <span key={group.id} title="Owner/Manager">
                          <Home color="#6666DD" />
                        </span>
                      );
                    case 'Administrators':
                      return (
                        <span key={group.id} title="Administrator">
                          <Users color="#FF0000" size={20} />
                        </span>
                      );
                    default:
                      return (
                        <span key={group.id} title="Player">
                          <User color="#888888" size={20} />
                        </span>
                      );
                  }
                })}
              </td>
              <td>
                {item.isActive ? (
                  <span className="clickable-avatar" title="Active" onClick={() => onToggleActive(item, false)}>
                    <CheckCircle color="green" />
                  </span>
                ) : (
                  <span className="clickable-avatar" title="Inactive" onClick={() => onToggleActive(item, true)}>
                    <AlertCircle color="red" />
                  </span>
                )}
              </td>
              <td className="actions">
                <Link to={`/users/${item.id}/edit`} className="action">
                  <Edit color="#999" size={15} />
                </Link>
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
    <div className="row query-footer">
      <div className="page-size form-inline col-sm-6">
        <select defaultValue={pagination.pageSize} className="form-control input-sm" onChange={(e) => onPageSizeChange(e.target.value)}>
          {map(LIMITS, (opt) => (
            <option key={opt} value={opt}>
              {opt}
            </option>
          ))}
        </select>
        {' per page'}
      </div>
      <div className="col-sm-6 text-right">
        <Pagination
          onChange={onPageNumberChange}
          showLessItems
          current={pagination.pageNumber}
          total={pagination.totalCount}
          pageSize={pagination.pageSize || 20}
        />
      </div>
    </div>
  </div>
);

UsersTable.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape),
  pagination: PropTypes.objectOf(PropTypes.shape),
  onPageSizeChange: PropTypes.func.isRequired,
  onPageNumberChange: PropTypes.func.isRequired,
  onSearchUsers: PropTypes.func.isRequired,

  onToggleActive: PropTypes.func.isRequired,

  isLoading: PropTypes.bool,
  isSuccess: PropTypes.bool,
  isFailed: PropTypes.bool,
  errorMessage: PropTypes.string,
};
UsersTable.defaultProps = {
  isLoading: false,
  isSuccess: false,
  isFailed: false,
  errorMessage: '',
  list: [],
  pagination: {
    pageNumber: 1,
    pageSize: 20,
    totalCount: 1000,
  },
};

export default UsersTable;
