import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { extend } from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { userCanView } from '../../roles/index';
import GenericActions from '../../actions/genericActions';
import './style.css';
import { CMS_URL, HUBSPOT_URL } from '../../config';

const NAV_ITEMS = [
  {
    title: 'Events',
    url: '/events',
    subMenu: [
      {
        title: 'Event Templates',
        url: '/event-templates',
      },
      {
        title: 'Tournaments',
        url: '/tournaments',
      },
    ],
  },
  {
    title: 'Accounts',
    url: '/accounts',
    subMenu: [
      {
        title: 'Locations',
        url: '/locations',
      },
      {
        title: 'Billing Accounts',
        url: '/billing-accounts',
      },
      {
        title: 'Invoices',
        url: '/invoices',
      },
    ],
  },

  {
    title: 'Teams',
    url: '/teams',
    subMenu: [
      {
        title: 'Leaderboard',
        url: '/teams/leaderboard',
      },
      {
        title: 'Users',
        url: '/users',
      },
    ],
  },
  {
    title: 'Web Forms',
    url: '/contact',
    subMenu: [
      {
        title: 'Host Applications',
        url: '/contact/applications',
      },
      {
        title: 'Leads',
        url: '/leads',
      },
      // {
      //   title: 'Payments',
      //   url: '/payments',
      // },
    ],
  },
  {
    title: 'CMS',
    link: CMS_URL,
    subMenu: [
      {
        title: 'Hubspot',
        link: HUBSPOT_URL,
      },
    ],
  },
  // {
  //   title: 'Hosts',
  //   url: '/hosts',
  // },

  // {
  //   title: 'Tournament',
  //   url: '/tournament',
  //   subMenu: [
  //     {
  //       title: '2018 Leaderboard',
  //       url: '/tournament/leaderboard',
  //     },
  //     {
  //       title: '2017',
  //       url: '/tournament/2017',
  //     },
  //     {
  //       title: '2016',
  //       url: '/tournament/2016',
  //     },
  //   ],
  // },
  // {
  //   title: 'About',
  //   url: '/about',
  // },
  // {
  //   title: 'Hosts',
  //   url: '/hosts',
  //   user: 'hosts',
  //   subMenu: [
  //     {
  //       title: 'Documentation',
  //       url: '/hosts/docs',
  //     },
  //   ],
  // },
  // {
  //   title: 'Contact',
  //   url: '/contact',
  // },
];

const Header = ({ authenticated, userProfile, currentPage, logout, isSidebarOpen, toggleSidebar }) => (
  <header className="header">
    <div className="login-status">
      {authenticated ? (
        <div className="container-fluid text-right">
          {userProfile && userProfile.registeredTeam && userProfile.registeredTeam.teamUsername ? (
            <span className="user-name">
              <Link to="/registered-teams">
                {'TEAM USERNAME: '}
                {userProfile.registeredTeam.teamUsername}
              </Link>
            </span>
          ) : (
            <span className="user-register">
              <Link to="/register">Create a Team</Link>
            </span>
          )}
          <span className="separator" />
          <span className="dropdown">
            <span className="blue toggle">
              MY ACCOUNT
              <span className="chevron-down" />
            </span>
            <ul>
              <li>
                <Link to="/registered-teams">MY TEAMS</Link>
              </li>
              <li>
                <Link to="/profile">PROFILE</Link>
              </li>
              <li>
                <span className="pseudo-link" role="presentation" onClick={() => logout()}>
                  LOGOUT
                </span>
              </li>
            </ul>
          </span>
        </div>
      ) : (
        <div className="container-fluid text-right">
          <Link to="/login">SIGN IN</Link>
          <span className="separator" />
          <Link to="/register" className="green">
            SIGN UP
          </Link>
        </div>
      )}
    </div>
    <div className="main-header">
      <div className="container-fluid">
        <div className="filler" />
        <Link to="/" className="col-md-2">
          <span className="logo" />
        </Link>
        <div onClick={toggleSidebar} role="presentation" className={`hamburger-menu-toggle ${isSidebarOpen ? 'open' : ''}`}>
          <span />
          <span />
          <span />
        </div>
        <nav className="col-md-10">
          <ul>
            {NAV_ITEMS.map((item) =>
              item.user && !userCanView(item.user) ? (
                <span key={item.url} />
              ) : (
                <li key={item.url} className={`${currentPage === item.url ? 'active' : ''}${item.subMenu ? ' dropdown' : ''}`}>
                  {item.url && <Link to={item.url}>{item.title}</Link>}
                  {item.link && (
                    <a href={item.link} target="_blank" rel="noopener noreferrer">
                      {item.title}
                    </a>
                  )}
                  {item.subMenu && <span className="chevron-down" />}
                  {item.subMenu && (
                    <ul>
                      {item.subMenu.map((subItem) => (
                        <li key={subItem.url}>
                          {subItem.url && <Link to={subItem.url}>{subItem.title}</Link>}
                          {subItem.link && (
                            <a href={subItem.link} target="_blank" rel="noopener noreferrer">
                              {subItem.title}
                            </a>
                          )}
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ),
            )}
          </ul>
        </nav>
      </div>
    </div>
    <div className={`mobile-sidebar ${isSidebarOpen ? 'open' : ''}`}>
      <ul>
        {NAV_ITEMS.map((item) =>
          item.user && !userCanView(item.user) ? (
            <span key={item.url} />
          ) : (
            <li
              role="presentation"
              key={item.url}
              onClick={toggleSidebar}
              className={`${currentPage === item.url ? 'active' : ''}${item.subMenu ? ' dropdown' : ''}`}
            >
              {item.url && <Link to={item.url}>{item.title}</Link>}
              {item.link && (
                <a href={item.link} target="_blank" rel="noopener noreferrer">
                  {item.title}
                </a>
              )}
              {item.subMenu && (
                <ul>
                  {item.subMenu.map((subItem) => (
                    <li key={subItem.url} role="presentation" onClick={toggleSidebar}>
                      {subItem.url && (
                        <Link onClick={toggleSidebar} to={subItem.url}>
                          {subItem.title}
                        </Link>
                      )}
                      {subItem.link && (
                        <a href={subItem.link} onClick={toggleSidebar} target="_blank" rel="noopener noreferrer">
                          {subItem.title}
                        </a>
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ),
        )}
      </ul>
      <hr />
      {userProfile ? (
        <div className="container-fluid nav">
          <Link onClick={toggleSidebar} to="/registered-teams">
            MY TEAMS
          </Link>
          <Link onClick={toggleSidebar} to="/profile">
            MY PROFILE
          </Link>
          <span role="presentation" className="pseudo-link" onClick={() => toggleSidebar() && logout()}>
            LOGOUT
          </span>
        </div>
      ) : (
        <div className="container-fluid nav">
          <Link onClick={toggleSidebar} to="/login">
            SIGN IN
          </Link>
          <Link onClick={toggleSidebar} to="/register" className="green">
            SIGN UP
          </Link>
        </div>
      )}
    </div>
  </header>
);

Header.propTypes = {
  currentPage: PropTypes.string.isRequired,
  logout: PropTypes.func.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
  authenticated: PropTypes.bool,
  userProfile: PropTypes.objectOf(PropTypes.shape),
  isSidebarOpen: PropTypes.bool,
};
Header.defaultProps = {
  authenticated: false,
  userProfile: {},
  isSidebarOpen: false,
};

const mapStateToProps = (state) => extend({}, state.global);
const matchDispatchToProps = (dispatch) => bindActionCreators(extend({}, GenericActions), dispatch);
export default connect(mapStateToProps, matchDispatchToProps)(Header);
